import React from 'react'
import { truck_content } from "../../../../actions/yardActions/TruckAction";
import { connect } from "react-redux"
import Add_Image from "../../../../images/inspection/add_image.svg"

const InspectionImg = (props) => {
    return (
        <>
            <div className="ins_img">
                <h3>photos</h3>
                <div className="ins_images">
                    <div className="upload_img">
                        <img src={Add_Image} alt="inspection img" />
                    </div>
                    {/* <div className="ins_img_show">
                        <img src={state.img} alt="inspection img" />
                        <span id="ins_cancel" className="material-icons">close</span>
                    </div>
                    <div className="ins_img_show">
                        <img src={state.img} alt="inspection img" />
                        <span id="ins_cancel" className="material-icons">close</span>
                    </div>
                    <div className="ins_img_show">
                        <img src={state.img} alt="inspection img" />
                        <span id="ins_cancel" className="material-icons">close</span>
                    </div> */}
                </div>
                {/* <p className="ins_img_last">2 more</p> */}
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    truckData:state.truck.truckData,
    single_truck:state.truck.single_truck
  })
export default connect(mapStateToProps, {truck_content})(InspectionImg)